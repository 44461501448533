import { InvoiceStatus } from "@api/core/logistics/invoices/invoices.model";

export type Invoice = {
  name: string;
  logistics_provider: string;
  brand?: string;
  warehouse: string;
  services: string[];
  logistics_charges: number;
  favorable_errors_count: number;
  favorable_errors_dollars: number;
  unfavorable_errors_count: number;
  unfavorable_errors_dollars: number;
  unfavorable_errors_rejected_count: number;
  unfavorable_errors_rejected_dollars: number;
  credit_requested_errors_count: number;
  credit_requested_errors_dollars: number;
  creditable_errors_count: number;
  creditable_errors_dollars: number;
  start_date: string;
  end_date: string;
  status: InvoiceStatus;
};

export enum InvoiceTableHeader {
  INVOICE = "Invoice",
  STATUS = "Status",
  ISSUED_DATE = "Period End",
  LOGISTICS_PROVIDER = "3PL",
  CHARGES = "Charges",
  ERRORS = "Creditable Errors ($)",
}

export type InvoiceSortCriteria = {
  key: InvoiceTableHeader;
  order: "ascending" | "descending";
};

export type InvoiceHightlightTotals = {
  savingsDollars: number;
  savingsCount: number;
  requestedDollars: number;
  requestedCount: number;
  recoverableDollars: number;
  totalCount: number;
  totalCountOutstanding: number;
};
