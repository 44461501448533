import { z } from 'zod';

export enum ScenarioTypes {
  LCC_OPTION = 'LCC_OPTION', // cheapest option with the higher difference_amount
  ALTERNATIVE = 'ALTERNATIVE', // additional options we've identified
}

export enum SalesOrderErrorStatus {
  UNREVIEWED = 'UNREVIEWED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  SENT = 'SENT',
  NO_REVIEW_REQUIRED = 'NO_REVIEW_REQUIRED',
}

export enum ServiceType {
  PICK_N_PACK = 'PICK_N_PACK',
  FREIGHT = 'FREIGHT',
}

export enum SalesOrderStatus {
  UNREVIEWED = 'UNREVIEWED',
  REVIEWED = 'REVIEWED',
  NO_REVIEW_REQUIRED = 'NO_REVIEW_REQUIRED',
  UNDER_REVIEW = 'UNDER_REVIEW',
  CREDITS_PARTIALLY_REQUESTED = 'CREDITS_PARTIALLY_REQUESTED',
  CREDITS_REQUESTED = 'CREDITS_REQUESTED',
}

export enum SalesOrderStatusByServiceType {
  UNREVIEWED = 'UNREVIEWED',
  REVIEWED_ACCEPTED = 'REVIEWED_ACCEPTED',
  REVIEWED_REJECTED = 'REVIEWED_REJECTED',
  NO_REVIEW_REQUIRED = 'NO_REVIEW_REQUIRED',
  CREDITS_REQUESTED = 'CREDITS_REQUESTED',
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SortBy {
  SALES_ORDER_NAME = 'SALES_ORDER_NAME',
  STATUS = 'STATUS',
  ERROR_TYPES = 'ERROR_TYPES',
  SERVICES = 'SERVICES',
  TOTAL_ERROR_DOLLARS = 'TOTAL_ERROR_DOLLARS',
  TOTAL_ERROR_COUNT = 'TOTAL_ERROR_COUNT',
  TOTAL_UNFAVORABLE_DOLLARS = 'TOTAL_UNFAVORABLE_DOLLARS',
  TOTAL_FAVORABLE_DOLLARS = 'TOTAL_FAVORABLE_DOLLARS',
  TOTAL_UNFAVORABLE_COUNT = 'TOTAL_UNFAVORABLE_COUNT',
  TOTAL_FAVORABLE_COUNT = 'TOTAL_FAVORABLE_COUNT',
}

export enum ErrorGroupSortBy {
  SALES_ORDER_NAME = 'SALES_ORDER_NAME',
  STATUS = 'STATUS',
  SERVICES = 'SERVICES',
  TOTAL_ERROR_DOLLARS = 'TOTAL_ERROR_DOLLARS',
}

export enum DefaultErrorGroups {
  ALL_CREDITABLE = 'ALL_CREDITABLE',
  ALL_ORDERS = 'ALL_ORDERS',
  ALL_NON_CREDITABLE = 'ALL_NON_CREDITABLE',
  ALL_ERROR_ORDERS = 'ALL_ERROR_ORDERS',
  ALL_UNMATCHING_ORDERS = 'ALL_UNMATCHING_ORDERS',
}

export enum QueryType {
  STATUS = 'STATUS',
  ERROR_TYPES = 'ERROR_TYPES',
  SERVICES = 'SERVICES',
}

export enum QueryLinker {
  AND = 'AND',
  OR = 'OR',
}

export const SalesOrderQuerySchema = z.object({
  pageSize: z.number().int().min(1).max(200).default(30),
  page: z.number().int().min(1).default(1),
  sort: z.nativeEnum(SortOrder).default(SortOrder.ASC),
  sortBy: z.nativeEnum(SortBy).default(SortBy.SALES_ORDER_NAME),
  salesOrderNumberSearch: z.string().optional(),
  query: z.array(z.object({ type: z.nativeEnum(QueryType), value: z.string() })).optional(),
  linkers: z.array(z.nativeEnum(QueryLinker)).optional(),
});

export const SalesOrderErrorGroupQuerySchema = z.object({
  pageSize: z.number().int().min(1).max(200).default(30),
  page: z.number().int().min(1).default(1),
  sort: z.nativeEnum(SortOrder).default(SortOrder.ASC),
  sortBy: z.nativeEnum(ErrorGroupSortBy).default(ErrorGroupSortBy.SALES_ORDER_NAME),
  salesOrderNumberSearch: z.string().optional(),
  errorGroup: z.string().or(z.nativeEnum(DefaultErrorGroups)).optional(),
  statuses: z.array(z.nativeEnum(SalesOrderStatus)),
});

export const ErrorGroupFilterSchema = z.object({
  totalErrorsCount: z.number(),
  totalCreditableErrorsDollars: z.number(),
  totalOrdersCount: z.number(),
  ErrorGroups: z.array(
    z.object({
      errorGroupName: z.string(),
      errorRootCauses: z.array(
        z.object({
          rootCause: z.string(),
          errorDescription: z.string(),
        }),
      ),
      orderNum: z.number(),
      creditableErrorsDollars: z.number(),
      creditableErrorsCount: z.number(),
    }),
  ),
});

export const InvoiceErrorGroupSalesOrderSchema = z.object({
  salesOrderNumber: z.string(),
  status: z.nativeEnum(SalesOrderStatus),
  services: z.array(z.string()),
  creditableErrorsDollars: z.number(),
});

export const InvoiceSalesOrderSchema = z.object({
  salesOrderNumber: z.string(),
  status: z.nativeEnum(SalesOrderStatus),
  errorType: z.array(z.string()),
  services: z.array(z.string()),
  totalErrorDollars: z.number(),
  totalErrorsCount: z.number(),
  favorableTotalCount: z.number(),
  unfavorableTotalCount: z.number(),
  favorableDollarsTotal: z.number(),
  unfavorableDollarsTotal: z.number(),
  creditableErrorsDollars: z.number(),
  creditableErrorsCount: z.number(),
  totalAcceptedErrors: z.number(),
  totalRejectedErrors: z.number(),
  totalUnreviewedErrors: z.number(),
  totalSentErrors: z.number(),
});

export const QueryMetaDataSchema = z.object({
  total: z.number(),
  totalFiltered: z.number(),
  page: z.number(),
  pageSize: z.number(),
  totalPageCount: z.number(),
});

export const SalesOrderErrorsSchema = z.object({
  salesOrderNumber: z.string(),
  invoiceNumber: z.string(),
  brandId: z.string(),
  status: z.nativeEnum(SalesOrderErrorStatus),
  errorType: z.string(),
  totalErrorDollars: z.number(),
  unfavorableDollars: z.number(),
  favorableDollars: z.number(),
  unfavorableCount: z.number(),
  favorableCount: z.number(),
  totalErrorCount: z.number(),
  services: z.array(z.string()),
  serviceType: z.nativeEnum(ServiceType),
});

export const SalesOrderSchema = z.object({
  id: z.string(),
  salesOrderNumber: z.string(),
  brandId: z.string(),
  status: z.nativeEnum(SalesOrderStatus),
  invoiceNumber: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const OrderOverviewSchema = z.object({
  errorCount: z.number(),
  invoiceNumber: z.string(),
  reconcilationLevel: z.string().optional(),
  orderFulfilmentDetail: z.object({
    logisticsProvider: z.string(),
    warehouse: z.string(),
  }),
  orderTimeline: z.array(
    z.object({
      date: z.date().nullable(),
      event: z.enum(['Order Date', 'Fulfilment Date', 'Ship Date']),
    }),
  ),
  freight: z.object({
    charges: z.array(
      z.object({
        errorType: z.string().nullable(),
        errorDifference: z.number().nullable(),
        expectedTotalCharges: z.number().nullable(),
        invoicedTotalCharges: z.number().nullable(),
        service: z.string(),
      }),
    ),
    totalFreight: z.object({
      expectedTotalCharges: z.number().nullable(),
      invoicedTotalCharges: z.number().nullable(),
      differencesTotal: z.number().nullable(),
      errorsTotal: z.number(),
    }),
    details: z.object({
      carrier: z.string().nullable(),
      serviceLevel: z.string().nullable(),
      zone: z.string().nullable(),
      actualWeight: z.number().nullable(),
      roundedWeight: z.number().nullable(),
      dimensionalWeight: z.number().nullable(),
      billedWeight: z.number().nullable(),
    }),
  }),
  pickAndPack: z.object({
    lineItemDetails: z.array(
      z.object({
        isConstituent: z.boolean().default(false).optional(),
        errorType: z.string().nullable(),
        expectedQuantity: z.number().nullable(),
        expectedRate: z.number().nullable(),
        expectedTotalCharges: z.number().nullable(),
        invoicedQuantity: z.number().nullable(),
        invoicedRate: z.number().nullable(),
        invoicedTotalCharges: z.number().nullable(),
        errorDifference: z.number().nullable(),
        item: z.string(),
        productName: z.string().nullable(),
        service: z.string(),
      }),
    ),
    orderInsertFees: z.array(
      z.object({
        isConstituent: z.boolean().default(false).optional(),
        errorType: z.string().nullable(),
        expectedQuantity: z.number().nullable(),
        expectedRate: z.number().nullable(),
        expectedTotalCharges: z.number().nullable(),
        invoicedQuantity: z.number().nullable(),
        invoicedRate: z.number().nullable(),
        invoicedTotalCharges: z.number().nullable(),
        errorDifference: z.number().nullable(),
        service: z.string(),
      }),
    ),
    totalOrderLevelFees: z.object({
      expectedQuantity: z.number().nullable(),
      expectedRate: z.number().nullable(),
      invoicedQuantity: z.number().nullable(),
      invoicedRate: z.number().nullable(),
      errorsTotal: z.number(),
      differencesTotal: z.number(),
    }),
    totalPickAndPack: z.object({
      expectedQuantity: z.number().nullable(),
      expectedRate: z.number().nullable(),
      invoicedQuantity: z.number().nullable(),
      invoicedRate: z.number().nullable(),
      errorsTotal: z.number(),
      differencesTotal: z.number(),
    }),
    totalPickFees: z.object({
      expectedQuantity: z.number().nullable(),
      expectedRate: z.number().nullable(),
      invoicedQuantity: z.number().nullable(),
      invoicedRate: z.number().nullable(),
      errorsTotal: z.number(),
      differencesTotal: z.number(),
    }),
  }),
  status: z.nativeEnum(SalesOrderStatus),
  totalCostSummary: z.object({
    errorTypes: z.array(z.string()),
    errorsTotal: z.number(),
    differencesTotal: z.number(),
    expectedRate: z.number(),
    invoicedRate: z.number(),
  }),
  type: z.string(),
});

export const ErrorStatusUpdateSchema = z.object({
  salesOrderNumber: z.string(),
  errorType: z.string(),
  serviceType: z.string(),
  status: z.nativeEnum(SalesOrderErrorStatus),
});

export const AcceptedOrderErrorSchema = z.object({
  errorType: z.string(),
  serviceType: z.string(),
  errorsCount: z.number(),
  errorsDollars: z.number(),
});
export type AcceptedOrderErrorSchema = z.infer<typeof AcceptedOrderErrorSchema>;

export interface SalesOrderWithErrors {
  salesOrderNumber: string;
  invoiceNumber: string;
  brandId: string;
  errors: SalesOrderError[];
}

export const ServiceChargeSchema = z.object({
  invoice_number: z.string(),
  item: z.string().nullable(),
  order_number: z.string(),
  service: z.string(),
  error: z.string(),
  rate: z.number().nullable().transform(Number),
  quantity: z.number(),
  quantity_uom: z.string().nullable(),
  total_cost: z.number(),
});

export const ReconciledServiceChargeSchema = z.object({
  invoice_number: z.string(),
  order_number: z.string(),
  service: z.string(),
  error: z.string(),
  expected_item: z.string().nullable(),
  expected_rate: z.number().nullable().transform(Number),
  expected_quantity: z.number(),
  expected_quantity_uom: z.string().nullable(),
  expected_total_cost: z.number(),
  actual_item: z.string().nullable(),
  actual_rate: z.number().nullable().transform(Number),
  actual_quantity: z.number(),
  actual_quantity_uom: z.string().nullable(),
  actual_total_cost: z.number(),
  error_total: z.number(),
});

export const ClientSalesOrderErrorServiceCharge = z.object({
  related_service_charge_ids: z.array(
    z.object({
      related_service_charges: z.object({
        real_service_charges: z
          .array(
            z.object({
              real_service_charge: ServiceChargeSchema,
            }),
          )
          .optional(),
        synthetic_service_charges: z
          .array(
            z.object({
              synthetic_service_charge: ServiceChargeSchema,
            }),
          )
          .optional(),
        reconciled_service_charges: z
          .array(
            z.object({
              reconciled_service_charge: ReconciledServiceChargeSchema,
            }),
          )
          .optional(),
      }),
    }),
  ),
});

export const CDMSalesOrderErrorSchema = z.object({
  sales_order_number: z.string(),
  brand_id: z.string(),
  invoice_number: z.string(),
  reconciliation_run_id: z.string(),
  related_service_charge_ids: ClientSalesOrderErrorServiceCharge.nullish(),
  difference_root_cause: z.string().nullable(),
  service: z.string(),
  total_difference_amount: z.string().transform(Number),
  total_unfavorable_amount: z.string().transform(Number),
  total_favorable_amount: z.string().transform(Number),
  unfavorable_count: z.string().transform(Number),
  favorable_count: z.string().transform(Number),
  total_error_count: z.string().transform(Number),
  scenario_metadata: z.record(z.unknown()).nullish(),
});

export const AcceptedSelectSalesOrderErrors = z.object({
  id: z.string().uuid(),
  salesOrderNumber: z.string(),
  errorType: z.string(),
  serviceType: z.string(),
  service: z.string(),
  related_service_charge_ids: ClientSalesOrderErrorServiceCharge,
  totalErrorsDollars: z.number(),
  totalErrorsCount: z.number(),
});

export type SelectedAcceptedSalesOrderErrors = Omit<
  RawAcceptedSelectSalesOrderErrors,
  'actualCharge' | 'expectedCharge' | 'carrier' | 'actualWeight' | 'expectedWeight' | 'actualZone' | 'expectedZone'
> &
  Partial<
    Pick<
      RawAcceptedSelectSalesOrderErrors,
      'actualCharge' | 'expectedCharge' | 'carrier' | 'actualWeight' | 'expectedWeight' | 'actualZone' | 'expectedZone'
    >
  >;

export type RawAcceptedSelectSalesOrderErrors = {
  actualCharge: string;
  expectedCharge: string;
  carrier?: string | undefined;
  actualWeight?: number | undefined;
  expectedWeight?: number | undefined;
  actualZone?: string | undefined;
  expectedZone?: string | undefined;
  id: string;
  salesOrderNumber: string;
  errorType: string;
  serviceType: string;
  service: string;
  related_service_charge_ids: ClientSalesOrderErrorServiceCharge;
  totalErrorsDollars: number;
  totalErrorsCount: number;
};

export const AlternateSalesOrder = z.object({
  sales_order_number: z.string(),
  alternate_sales_order_number: z.string(),
  brand_id: z.string(),
  invoice_number: z.string(),
  type: z.string(),
});

export const ClientSalesOrderStatus = z.object({
  sales_order_number: z.string(),
  brand_id: z.string(),
  invoice_number: z.string(),
});

export const OrderLccSchema = z.object({
  isLccEnabled: z.boolean(),
  charges: z
    .object({
      type: z
        .nativeEnum(ScenarioTypes)
        .describe(`What kind of alternate scenario is this? i.e ${Object.values(ScenarioTypes).join(', ')}`),
      favorableDollarsTotal: z
        .number()
        .describe(
          "Difference amount we've identified between the invoiced amount and the least cost carrier amount. The larger the better.",
        ),
      unfavorableDollarsTotal: z
        .number()
        .describe(
          'Difference amount we have identified between the invoiced amount and the least cost carrier amount.',
        ),
      zone: z.string().describe('Freight zone used i.e 1, 2, 3, 4, 5, 6, 7, 8, 9, 10.'),
      carrier: z.string().describe('Freight carrier i.e Fedex.'),
      serviceLevel: z.string().describe('Service level i.e Ground Level.'),
    })
    .array(),
});

export const OrderSummaryDetailsSchema = z.object({
  id: z.string(),
  brand_id: z.string(),
  invoice_number: z.string(),
  sales_order_number: z.string(),
  sales_order_type: z.string(),
  level: z.string(),
  expected_total: z.string(),
  invoiced_total: z.string(),
  error: z.string().nullable(),
  difference: z.string(),
  error_count: z.string().transform(Number),
});

export type OrderSummaryDetails = z.infer<typeof OrderSummaryDetailsSchema>;
export type ClientSalesOrderStatus = z.infer<typeof ClientSalesOrderStatus>;
export type AcceptedSelectSalesOrderErrors = z.infer<typeof AcceptedSelectSalesOrderErrors>;
export type ClientSalesOrderErrorServiceCharge = z.infer<typeof ClientSalesOrderErrorServiceCharge>;
export type ServiceCharge = z.infer<typeof ServiceChargeSchema>;
export type ReconciledServiceCharge = z.infer<typeof ReconciledServiceChargeSchema>;
export type AlternateSalesOrder = z.infer<typeof AlternateSalesOrder>;
export type CDMSalesOrderError = z.infer<typeof CDMSalesOrderErrorSchema>;
export type SalesOrderQuery = z.infer<typeof SalesOrderQuerySchema>;
export type InvoiceSalesOrder = z.infer<typeof InvoiceSalesOrderSchema>;
export type SalesOrder = z.infer<typeof SalesOrderSchema>;
export type QueryMetaData = z.infer<typeof QueryMetaDataSchema>;
export type SalesOrderError = z.infer<typeof SalesOrderErrorsSchema>;
export type ErrorStatusUpdate = z.infer<typeof ErrorStatusUpdateSchema>;
export type OrderOverview = z.infer<typeof OrderOverviewSchema>;
export type OrderLccSchema = z.infer<typeof OrderLccSchema>;
export type ErrorGroupFilter = z.infer<typeof ErrorGroupFilterSchema>;
export type InvoiceErrorGroupSalesOrder = z.infer<typeof InvoiceErrorGroupSalesOrderSchema>;
export type SalesOrderErrorGroupQuery = z.infer<typeof SalesOrderErrorGroupQuerySchema>;
