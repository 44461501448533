import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  RequestCreditsComposeMessage,
  RequestCreditsInvoice,
  RequestCreditsMessage,
  RequestCreditsOrders,
} from "@/types/requestCredits";
import { userLoggedOut } from "../auth/authSlice";

type RequestCreditsState = {
  orders: RequestCreditsOrders[];
  invoice: RequestCreditsInvoice | undefined;
  message: RequestCreditsMessage | undefined;
  composeMessage: RequestCreditsComposeMessage;
  current_page_num: number;
  success: boolean;
};

const initialState: RequestCreditsState = {
  orders: [],
  invoice: undefined,
  message: undefined,
  composeMessage: {
    to_email: "",
    to_provider: "",
    brand_name: "",
    subject: "",
    message: "",
  },
  current_page_num: 0,
  success: false,
};

type SetRequestCreditsOrdersPayload = {
  orders: RequestCreditsOrders[];
};

type SetRequestCreditsInvoicePayload = {
  invoice: RequestCreditsInvoice;
};

type SetRequestCreditsMessagePayload = {
  message: RequestCreditsMessage;
};

type SetRequestCreditsComposeMessagePayload = {
  composeMessage: RequestCreditsComposeMessage;
};

export const requestCreditsSlice = createSlice({
  name: "requestCredits",
  initialState,
  reducers: {
    setRequestCreditsOrders: (
      state,
      action: PayloadAction<SetRequestCreditsOrdersPayload>
    ) => {
      state.orders = action.payload.orders;
    },
    setRequestCreditsInvoice: (
      state,
      action: PayloadAction<SetRequestCreditsInvoicePayload>
    ) => {
      state.invoice = action.payload.invoice;
    },
    setRequestCreditsMessage: (
      state,
      action: PayloadAction<SetRequestCreditsMessagePayload>
    ) => {
      state.message = action.payload.message;
    },
    setRequestCreditsComposeMessage: (
      state,
      action: PayloadAction<SetRequestCreditsComposeMessagePayload>
    ) => {
      state.composeMessage = action.payload.composeMessage;
    },
    setRequestCreditsCurrentPageNum: (state, action: PayloadAction<number>) => {
      state.current_page_num = action.payload;
    },
    setRequestCreditsSuccess: (state, action: PayloadAction<boolean>) => {
      state.success = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLoggedOut, (_) => {
      return initialState;
    })
  },
});

export const {
  setRequestCreditsOrders,
  setRequestCreditsInvoice,
  setRequestCreditsMessage,
  setRequestCreditsCurrentPageNum,
  setRequestCreditsSuccess,
  setRequestCreditsComposeMessage,
} = requestCreditsSlice.actions;

export const selectRequestCreditOrders = (state: RootState) =>
  state.requestCredits.orders;
export const selectRequestCreditsInvoice = (state: RootState) =>
  state.requestCredits.invoice;
export const selectRequestCreditsMessage = (state: RootState) =>
  state.requestCredits.message;
export const selectRequestCreditsComposeMessage = (state: RootState) =>
  state.requestCredits.composeMessage;
export const selectRequestCreditCurrentPageNum = (state: RootState) =>
  state.requestCredits.current_page_num;
export const selectRequestCreditsSuccess = (state: RootState) =>
  state.requestCredits.success;

export default requestCreditsSlice.reducer;
