// packages/ui/src/services/store/invoices/invoiceDetailsSlice.ts

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ErrorSummary } from "@/types/errors";
import { InvoiceDetails } from "@/types/invoiceDetails";
import { userLoggedOut } from "../auth/authSlice";
import { ErrorGroupFilter } from "@api/core/logistics/sales-orders/sales-orders.schema";

type InvoiceDetailsState = {
  invoice: InvoiceDetails | undefined;
  errors: ErrorSummary[];
  invoiceErrorGroups: ErrorGroupFilter | undefined;
};

const initialState: InvoiceDetailsState = {
  invoice: undefined,
  errors: [],
  invoiceErrorGroups: undefined,
};

type SetInvoiceDetailsPayload = {
  invoice: InvoiceDetails | undefined;
  errorSummaries: ErrorSummary[];
};

const invoiceDetailsSlice = createSlice({
  name: "invoiceDetails",
  initialState,
  reducers: {
    setInvoiceDetails: (
      state,
      action: PayloadAction<SetInvoiceDetailsPayload>
    ) => {
      state.invoice = action.payload.invoice;
      state.errors = action.payload.errorSummaries;
    },
    setInvoiceErrorGroups: (state, action: PayloadAction<ErrorGroupFilter | undefined>) => {
      state.invoiceErrorGroups = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLoggedOut, (_) => {
      return initialState;
    });
  },
});

export const { setInvoiceDetails, setInvoiceErrorGroups } =
  invoiceDetailsSlice.actions;

export const selectInvoice = (state: RootState) => state.invoiceDetails.invoice;
export const selectInvoiceErrorSummaries = (state: RootState) =>
  state.invoiceDetails.errors;
export const selectInvoiceErrorGroups = (state: RootState) =>
  state.invoiceDetails.invoiceErrorGroups;

export default invoiceDetailsSlice.reducer;
