import { Order } from "@/types/order";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { DEFAULT_ORDER_ITEMS_PER_PAGE } from "@/consts/orderFilterConsts";
import { SalesOrderStatus } from "@api/core/logistics/sales-orders/sales-orders.schema";
import { userLoggedOut } from "../auth/authSlice";

type SetOrdersPayload = {
  orders: Order[];
};

type UpdateStatusOnOrdersPayload = {
  orderIds: string[];
  status: SalesOrderStatus;
};

type UpdateStatusOnSingleOrderPayload = {
  orderId: string;
  status: SalesOrderStatus;
};

type OrdersState = {
  orders: Order[] | undefined;
  ordersCurrentPage: number;
  currentPage: number;
  itemsPerPage: number;
  totalItemsCount: number;
  totalFilteredItemsCount: number;
};

const initialState: OrdersState = {
  orders: undefined,
  ordersCurrentPage: 0,
  currentPage: 0,
  itemsPerPage: DEFAULT_ORDER_ITEMS_PER_PAGE,
  totalItemsCount: 0,
  totalFilteredItemsCount: 0,
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setOrders: (state, action: PayloadAction<SetOrdersPayload>) => {
      state.orders = action.payload.orders;
    },
    setOrdersCurrentPage: (state, action: PayloadAction<number>) => {
      state.ordersCurrentPage = action.payload;
    },
    setOrderCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setOrderItemsPerPage: (state, action: PayloadAction<number>) => {
      state.itemsPerPage = action.payload;
    },
    setOrderTotalItemsCount: (state, action: PayloadAction<number>) => {
      state.totalItemsCount = action.payload;
    },
    setOrderTotalFilteredItemsCount: (state, action: PayloadAction<number>) => {
      state.totalFilteredItemsCount = action.payload;
    },
    updateStatusOnOrders: (
      state,
      action: PayloadAction<UpdateStatusOnOrdersPayload>
    ) => {
      action.payload.orderIds.forEach((orderId) => {
        const foundOrder = state.orders?.find(
          (order) => order.order_number === orderId
        );
        if (foundOrder) {
          foundOrder.status = action.payload.status;
        }
      });
    },
    updateStatusOnSingleOrder: (
      state,
      action: PayloadAction<UpdateStatusOnSingleOrderPayload>
    ) => {
      const foundOrder = state.orders?.find(
        (order) => order.order_number === action.payload.orderId
      );
      if (foundOrder) {
        foundOrder.status = action.payload.status;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLoggedOut, (_) => {
      return initialState;
    })
  },
});

export const {
  setOrders,
  setOrdersCurrentPage,
  setOrderCurrentPage,
  setOrderItemsPerPage,
  setOrderTotalItemsCount,
  setOrderTotalFilteredItemsCount,
  updateStatusOnOrders,
  updateStatusOnSingleOrder,
} = ordersSlice.actions;

export const selectOrders = (state: RootState) => state.orders.orders;
export const selectOrdersCurrentPage = (state: RootState) =>
  state.orders.ordersCurrentPage;
export const selectOrderCurrentPage = (state: RootState) =>
  state.orders.currentPage;
export const selectOrderItemsPerPage = (state: RootState) =>
  state.orders.itemsPerPage;
export const selectOrderTotalItemsCount = (state: RootState) =>
  state.orders.totalItemsCount;
export const selectOrderTotalFilteredItemsCount = (state: RootState) =>
  state.orders.totalFilteredItemsCount;

export default ordersSlice.reducer;
