import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { SalesOrderErrorsType } from "@/types/orderDetails";
import { userLoggedOut } from "../auth/authSlice";

type SetErrorsPayload = {
  errors: SalesOrderErrorsType[];
};

type ErrorsState = {
  errors: SalesOrderErrorsType[] | undefined;
};

const initialState: ErrorsState = {
  errors: undefined,
};

export const errorsSlice = createSlice({
  name: "errors",
  initialState,
  reducers: {
    setErrors: (state, action: PayloadAction<SetErrorsPayload>) => {
      state.errors = action.payload.errors;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLoggedOut, (_) => {
      return initialState;
    })
  },
});

export const { setErrors } = errorsSlice.actions;

export const selectErrors = (state: RootState) => state.errors.errors;

export default errorsSlice.reducer;
