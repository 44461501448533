export const ORDER_STATUS_CODE_TO_NAME = {
  UNREVIEWED: "Unreviewed",
  REVIEWED: "Reviewed",
  NO_REVIEW_REQUIRED: "No Review Required",
  UNDER_REVIEW: "Under Review",
  CREDITS_PARTIALLY_REQUESTED: "Credits Partially Requested",
  CREDITS_REQUESTED: "Credits Requested",
};

export const DEFAULT_STATUS_FILTERS = [
  ORDER_STATUS_CODE_TO_NAME.REVIEWED,
  ORDER_STATUS_CODE_TO_NAME.UNREVIEWED,
  ORDER_STATUS_CODE_TO_NAME.UNDER_REVIEW,
  ORDER_STATUS_CODE_TO_NAME.CREDITS_PARTIALLY_REQUESTED,
];

