import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

type AuthState = {
  isAuthenticated: boolean;
  newPasswordRequired?: {
    challenge: string;
    session: string;
    username: string;
  }
};

const initialState: AuthState = {
  isAuthenticated: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    userLoggedOut: (state) => {
      state.isAuthenticated = false;
    },
    setNewPasswordChallenge: (state, action: PayloadAction<{
      challenge: string;
      session: string;
      username: string;
    }>) => {
      state.newPasswordRequired = action.payload;
    },
    clearNewPasswordChallenge: (state) => {
      state.newPasswordRequired = undefined;
    }
  },
});

export const { setIsAuthenticated, userLoggedOut } = authSlice.actions;
export const { setNewPasswordChallenge } = authSlice.actions;
export const { clearNewPasswordChallenge } = authSlice.actions;

export const selectIsAuthenticated = (state: RootState) =>
  state.auth.isAuthenticated;

export const selectNewPasswordRequired = (state: RootState) => state.auth.newPasswordRequired;

export default authSlice.reducer;
