import {
  Credit,
  CreditStatus,
  CreditStatusChartCountTotals,
  CreditStatusHighlightCountTotals,
} from "@/types/credit";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { userLoggedOut } from "../auth/authSlice";

type CreditsSlice = {
  allCredits: Credit[];
  credits: Credit[];
  creditHighlightTotals: CreditStatusHighlightCountTotals;
  creditChartTotals: CreditStatusChartCountTotals;
};

const initialState: CreditsSlice = {
  allCredits: [],
  credits: [],
  creditHighlightTotals: {
    requested: 0,
    received: 0,
    unreceived: 0,
  },
  creditChartTotals: {
    received: 0,
    partially_received: 0,
    denied: 0,
    cancelled: 0,
    sent: 0,
  },
};

type SetCreditsPayload = {
  credits: Credit[];
};

type SetCreditStatusHighlightPayload = {
  creditHighlightTotals: CreditStatusHighlightCountTotals;
}

type SetCreditStatusChartPayload = {
  creditChartTotals: CreditStatusChartCountTotals;
}

type UpdateStatusOnCreditsPayload = {
  creditIds: string[];
  status: CreditStatus;
};

const creditsSlice = createSlice({
  name: "credits",
  initialState,
  reducers: {
    setAllCredits: (state, action: PayloadAction<SetCreditsPayload>) => {
      state.allCredits = action.payload.credits;
    },
    setCredits: (state, action: PayloadAction<SetCreditsPayload>) => {
      state.credits = action.payload.credits;
    },
    setCreditHighlightTotals: (state, action: PayloadAction<SetCreditStatusHighlightPayload>) => {
      state.creditHighlightTotals = action.payload.creditHighlightTotals;
    },
    setCreditChartTotals: (state, action: PayloadAction<SetCreditStatusChartPayload>) => {
      state.creditChartTotals = action.payload.creditChartTotals;
    },
    updateStatusOnCredits: (
      state,
      action: PayloadAction<UpdateStatusOnCreditsPayload>
    ) => {
      action.payload.creditIds.forEach((creditId) => {
        const foundCredit = state.credits.find(
          (credit) => credit.credit_request_number === creditId
        );
        if (foundCredit) {
          foundCredit.status = action.payload.status;
        }
        const foundCreditFromAllCredits = state.allCredits.find(
          (credit) => credit.credit_request_number === creditId
        );
        if (foundCreditFromAllCredits) {
          foundCreditFromAllCredits.status = action.payload.status;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLoggedOut, (_) => {
      return initialState;
    })
  },
});

export const { setAllCredits, setCredits, updateStatusOnCredits, setCreditHighlightTotals, setCreditChartTotals } = creditsSlice.actions;

export const selectAllCredits = (state: RootState) => state.credits.allCredits;
export const selectCredits = (state: RootState) => state.credits.credits;
export const selectCreditHighlightTotals = (state: RootState) => state.credits.creditHighlightTotals;
export const selectCreditChartTotals = (state: RootState) => state.credits.creditChartTotals;

export default creditsSlice.reducer;
