import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { userLoggedOut } from "../auth/authSlice";

type CreditPaginationState = {
  currentPage: number;
  itemsPerPage: number | undefined;
  totalItemsCount: number;
};

const initialState: CreditPaginationState = {
  currentPage: 0,
  itemsPerPage: undefined,
  totalItemsCount: 0,
};

export const creditPaginationSlice = createSlice({
  name: "creditPagination",
  initialState,
  reducers: {
    setCreditCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setCreditItemsPerPage: (state, action: PayloadAction<number>) => {
      state.itemsPerPage = action.payload;
    },
    setCreditTotalItemsCount: (state, action: PayloadAction<number>) => {
      state.totalItemsCount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLoggedOut, (_) => {
      return initialState;
    })
  },
});

export const {
  setCreditCurrentPage,
  setCreditItemsPerPage,
  setCreditTotalItemsCount,
} = creditPaginationSlice.actions;

export const selectCreditCurrentPage = (state: RootState) =>
  state.creditPagination.currentPage;
export const selectCreditItemsPerPage = (state: RootState) =>
  state.creditPagination.itemsPerPage;
export const selectCreditTotalItemsCount = (state: RootState) =>
  state.creditPagination.totalItemsCount;

export default creditPaginationSlice.reducer;
