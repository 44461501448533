"use client";

import { useToast } from "@implentio/ui-toolkit";
import {
  QueryClient,
  QueryClientProvider as TanstackQueryClientProvider,
} from "@/utils/react-query";
import { AxiosError } from "axios";
import { useEffect } from "react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});

export default function QueryClientProvider({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const { toast } = useToast();

  useEffect(() => {
    const queryCache = queryClient.getQueryCache();
    queryCache.config.onError = (error) => {
      console.error(error);
      // 401 errors are handled by Axios intercepter in HttpService.ts
      const errorStatus = (error as AxiosError).response?.status;
      if (errorStatus !== 401 && errorStatus !== 403) {
        toast({
          variant: "neutral",
          description: "Oops, something went wrong.",
        });
        queryClient.clear();
      }
    };

    const mutationCache = queryClient.getMutationCache();
    mutationCache.config.onError = (error) => {
      console.error(error);
      const errorStatus = (error as AxiosError).response?.status;
      if (errorStatus !== 401 && errorStatus !== 403) {
        toast({
          variant: "neutral",
          description: "Oops, something went wrong.",
        });
        queryClient.clear();
      }
    };
  }, [toast]);

  return (
    <TanstackQueryClientProvider client={queryClient}>
      {children}
    </TanstackQueryClientProvider>
  );
}
