import { userLoggedOut } from "../auth/authSlice";
import { RootState } from "../store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type invoiceErrorSummarySlice = {
  headers: {
    header: "orderCount" | "count" | "total_dollars";
    active: boolean;
    order: "ascending" | "descending" | "neutral";
  }[];
};

const initialState: invoiceErrorSummarySlice = {
  headers: [
    { header: "orderCount", active: false, order: "neutral" },
    { header: "count", active: false, order: "neutral" },
    { header: "total_dollars", active: false, order: "neutral" },
  ],
};

export const invoiceErrorSummarySlice = createSlice({
  name: "invoiceErrorSummary",
  initialState,
  reducers: {
    setInvoiceErrorSummary: (state, action: PayloadAction<number>) => {
      const clickedIndex = action.payload;
      const { headers } = state;

      headers[clickedIndex].active = true;
      switch (headers[clickedIndex].order) {
        case "neutral":
          headers[clickedIndex].order = "ascending";
          break;
        case "ascending":
          headers[clickedIndex].order = "descending";
          break;
        case "descending":
          headers[clickedIndex].order = "ascending";
          break;
      }
      headers.forEach((_, index) => {
        if (index !== clickedIndex) {
          headers[index].active = false;
          headers[index].order = "neutral";
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLoggedOut, (_) => {
      return initialState;
    })
  },
});

export const { setInvoiceErrorSummary } = invoiceErrorSummarySlice.actions;
export const selectInvoiceErrorSummary = (state: RootState) =>
  state.invoiceErrorSummarySlice.headers;
export default invoiceErrorSummarySlice.reducer;
