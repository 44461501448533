import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { InvoiceSortCriteria, InvoiceTableHeader } from "@/types/invoice";
import { userLoggedOut } from "../auth/authSlice";

type SetInvoiceSortCriteriaPayload = {
  sortCriteria: InvoiceSortCriteria;
};

type InvoiceSortState = {
  sortCriteria: InvoiceSortCriteria;
};

const initialState: InvoiceSortState = {
  sortCriteria: {
    key: InvoiceTableHeader.ISSUED_DATE,
    order: "descending",
  },
};

export const invoiceSortSlice = createSlice({
  name: "invoiceSort",
  initialState,
  reducers: {
    setInvoiceSortCriteria: (
      state,
      action: PayloadAction<SetInvoiceSortCriteriaPayload>
    ) => {
      state.sortCriteria = action.payload.sortCriteria;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLoggedOut, (_) => {
      return initialState;
    })
  },
});

export const { setInvoiceSortCriteria } = invoiceSortSlice.actions;

export const selectInvoiceSortCriteria = (state: RootState) =>
  state.invoiceSort.sortCriteria;

export default invoiceSortSlice.reducer;
