import { GroupedServiceDetail, ServiceDetail } from "@/types/service";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { userLoggedOut } from "../auth/authSlice";

type ServicesState = {
  servicesDetails: ServiceDetail[];
  groupedServicesDetails: GroupedServiceDetail[];
};

const initialState: ServicesState = {
  servicesDetails: [],
  groupedServicesDetails: [],
};

type SetServicesDetailsPayload = {
  servicesDetails: ServiceDetail[];
  groupedServicesDetails: GroupedServiceDetail[];
};

export const servicesDetailsSlice = createSlice({
  name: "servicesDetails",
  initialState,
  reducers: {
    setServicesDetails: (
      state,
      action: PayloadAction<SetServicesDetailsPayload>,
    ) => {
      state.servicesDetails = action.payload.servicesDetails;
      state.groupedServicesDetails = action.payload.groupedServicesDetails;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLoggedOut, (_) => {
      return initialState;
    })
  },
});

export const { setServicesDetails } = servicesDetailsSlice.actions;

export const selectServiceDetails = (state: RootState) =>
  state.servicesDetails.servicesDetails;

export const selectGroupedServiceDetails = (state: RootState) =>
  state.servicesDetails.groupedServicesDetails;

export default servicesDetailsSlice.reducer;
